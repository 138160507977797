import { Box } from '@silverstein-properties/inspirelabs-ui';
import { styled } from '@mui/material/styles';

export const AnimatedBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'isExpanded' && prop !== 'maxLines',
})<{ isExpanded: boolean; maxLines: number }>(
  ({ theme, isExpanded, maxLines }) => ({
    overflow: 'hidden',
    transition: theme.transitions.create('max-height', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
    maxHeight: isExpanded ? '1000px' : `${maxLines * 1.5}em`,
    position: 'relative',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: isExpanded ? 'unset' : maxLines,
  })
);
