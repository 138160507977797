import { AxiosInstance } from 'axios';
import { MerchantUserRole, User } from '@/types';
import { CreatePasswordInput } from '@/classes';
import { MerchantDropdown } from '@/types/Merchant';
import { Note } from '@/types/Note';

export const endpoints = {
  users: '/users',
  user({ userId }: { userId: string }) {
    return `/users/${userId}`;
  },
  resetPassword({ email }: { email: string }) {
    return `/users/${email}/password`;
  },
  isSuperAdmin: (userId: string) => `/users/${userId}/is-super-admin`,
  getAllAssociatedMerchants: (userId: string) => `/users/${userId}/merchants`,
  getUserRolesWithMerchant: (userId: string, merchantId: string) =>
    `/users/${userId}/roles-with-merchant/${merchantId}`,
  readAndWriteNote: (userId: string, merchantId: string) =>
    `/users/${userId}/notes/${merchantId}`,
  updateNote: (noteId: number) => `users/notes/${noteId}`,
};
export const users = ({ axiosInstance }: { axiosInstance: AxiosInstance }) => {
  return {
    getUserByEmail: async ({
      accessToken,
      email,
    }: {
      accessToken: string;
      email: string;
    }): Promise<User> => {
      const { data } = await axiosInstance.get(endpoints.users, {
        params: {
          email,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    },
    updateUser: async ({
      updatedUser,
      userId,
    }: {
      updatedUser: Partial<User>;
      userId: string;
    }): Promise<User> => {
      const { data } = await axiosInstance.patch(
        endpoints.user({ userId }),
        updatedUser
      );
      return data;
    },
    resetPassword: async ({
      email,
      ...resetPasswordDto
    }: CreatePasswordInput): Promise<boolean> => {
      const { data } = await axiosInstance.put(
        endpoints.resetPassword({ email }),
        resetPasswordDto
      );
      return data;
    },
    isSuperAdmin: async ({ userId }: { userId: string }): Promise<boolean> => {
      const { data } = await axiosInstance.get(endpoints.isSuperAdmin(userId));
      return data;
    },
    getAllAssociatedMerchants: async ({
      userId,
    }: {
      userId: string;
    }): Promise<MerchantDropdown[]> => {
      const { data } = await axiosInstance.get(
        endpoints.getAllAssociatedMerchants(userId)
      );
      return data;
    },
    getUserRolesWithMerchant: async ({
      userId,
      merchantId,
    }: {
      userId: string;
      merchantId: string;
    }): Promise<MerchantUserRole[]> => {
      const { data } = await axiosInstance.get(
        endpoints.getUserRolesWithMerchant(userId, merchantId)
      );
      return data;
    },
    createNote: async ({
      userId,
      merchantId,
      content,
      createdUserId,
    }: {
      userId: string;
      merchantId: string;
      content: string;
      createdUserId: number;
    }): Promise<Note> => {
      const { data } = await axiosInstance.post(
        endpoints.readAndWriteNote(userId, merchantId),
        { content: content, createdUserId: createdUserId }
      );
      return data;
    },
    updateNote: async ({
      noteId,
      content,
      updatedUserId,
    }: {
      noteId: number;
      content: string;
      updatedUserId: number;
    }): Promise<Note> => {
      const { data } = await axiosInstance.put(endpoints.updateNote(noteId), {
        content: content,
        updatedUserId: updatedUserId,
      });
      return data;
    },
    getNote: async ({
      userId,
      merchantId,
    }: {
      userId: string;
      merchantId: string;
    }): Promise<Note> => {
      const { data } = await axiosInstance.get(
        endpoints.readAndWriteNote(userId, merchantId)
      );
      return data;
    },
  };
};
