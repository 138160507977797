import { Membership } from '@/types';
import {
  Box,
  DataTable,
  DataTableEmpty,
  InputField,
  SearchIcon,
  Skeleton,
  Stack,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { useEffect, useState } from 'react';
import { useSearchMembershipPlanPurchases } from '@/hooks/useSearchMembershipPlanPurchases';
import { getMembersTableColumns } from './MembersTable.columns';
import {
  ExpandedState,
  PaginationState,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import MemberDetailsDrawer from './MemberDetailsDrawer';
import { DEFAULT_MEMBER_SEARCH_PARAMS, SEARCH_BOX } from '@/constants';
import { Pagination } from '@/components';
import { useLocation } from 'react-router';

// Loading skeleton
export const DataTableLoading = ({
  show = false,
  numberOfSkeletons = 3,
}: {
  show: boolean;
  numberOfSkeletons?: number;
}) => {
  return show ? (
    <Stack padding={5}>
      {[...Array(numberOfSkeletons)].map((_, index) => (
        <Skeleton key={index} height={60} />
      ))}
    </Stack>
  ) : null;
};

export type MembersSectionPropsType = {
  membership: Membership;
  onMembersNumberChange?: (value: number) => void;
};

export const MembersSection = ({
  membership,
  onMembersNumberChange,
}: MembersSectionPropsType) => {
  const theme = useTheme();
  const location = useLocation();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: DEFAULT_MEMBER_SEARCH_PARAMS.PAGE,
    pageSize: DEFAULT_MEMBER_SEARCH_PARAMS.PAGE_SIZE,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const rightAlignedColumns = ['totalMembers', 'statusDisplay', 'payment'];

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  // Debounce search term
  useEffect(() => {
    const handler = setTimeout(() => {
      // Replace multiple spaces with a single space and trim leading/trailing spaces
      const cleanedSearchTerm = searchTerm.replace(/\s+/g, ' ').trim();
      setDebouncedSearchTerm(cleanedSearchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    // Reset pagination to first page when search term changes
    setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
  };

  const {
    data: membershipPlanPurchases,
    metadata,
    isLoading,
  } = useSearchMembershipPlanPurchases({
    membershipId: membership.id,
    queryParams: {
      searchTerm: debouncedSearchTerm,
      page: pagination.pageIndex,
      pageSize: pagination.pageSize,
    },
  });

  const isMembershipPlanPurchasesEmpty =
    !isLoading && membershipPlanPurchases?.length === 0;
  const pageCount = metadata?.totalItems
    ? Math.ceil(metadata?.totalItems / pagination.pageSize)
    : -1; // Fall back value -1 is suggested by React Table for `pageCount`

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedPlanPurchaseId, setSelectedPlanPurchaseId] = useState('');

  const onMemberDetailsClick = (id: string) => {
    setSelectedPlanPurchaseId(id);
    setDrawerOpen(true);
  };

  useEffect(() => {
    if (!!location.state?.planPurchaseId) {
      setSelectedPlanPurchaseId(location.state.planPurchaseId);
      setDrawerOpen(true);
    }
  }, []);

  // For displaying label [ Members (x) ] above the table
  useEffect(() => {
    if (
      !isLoading &&
      membershipPlanPurchases?.length > 0 &&
      onMembersNumberChange
    ) {
      const members = membership.membershipPlans.reduce(
        (acc, membershipPlan) =>
          acc + (membershipPlan?.membersCount?.totalActiveMembers || 0),
        0
      );
      onMembersNumberChange(members);
    }
  }, [isLoading, membershipPlanPurchases, onMembersNumberChange]);

  const table = useReactTable({
    data: membershipPlanPurchases,
    columns: getMembersTableColumns(onMemberDetailsClick),
    pageCount: pageCount,
    state: {
      sorting,
      expanded,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getSubRows: row => row.members,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    columnResizeMode: 'onChange',
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  return (
    <Box>
      <InputField
        label="Search members"
        placeholder="Search ..."
        value={searchTerm}
        onChange={handleSearchChange}
        showFloatingLabel={false}
        notched={false}
        fullWidth={false}
        startAdornment={
          <SearchIcon
            width={theme.constants.ICON_SIZE.small}
            style={{ marginRight: theme.spacers.s }}
          />
        }
        sx={{
          borderRadius: theme.constants.BORDER_RADIUS.large,
          width: `${SEARCH_BOX.WIDTH_SHORT}px`,
          height: `${SEARCH_BOX.HEIGHT}px`,
          marginBottom: theme.spacers.m,
        }}
      />

      {/* TODO: Looking into refactoring DataTable component to have an optional internal loading state & empty state (default & customizable) */}
      <DataTable table={table} rightAlignedColumns={rightAlignedColumns} />
      <DataTableLoading
        show={isLoading}
        numberOfSkeletons={pagination.pageSize}
      />
      <DataTableEmpty
        show={!isLoading && membershipPlanPurchases.length === 0}
      />
      <Pagination
        show={!isMembershipPlanPurchasesEmpty}
        table={table}
        totalItems={metadata?.totalItems || 0}
      />

      {/* ======================================================== */}

      <MemberDetailsDrawer
        title="Member details"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onClickAway={() => setDrawerOpen(false)}
        membershipPlanPurchaseId={selectedPlanPurchaseId}
      />
    </Box>
  );
};
