import { ScreensViews } from '@/hooks/useMembershipEdit';
import { Experience, RefundPolicy } from './Experience';
import { Price } from './Price';

export enum SubscriptionType {
  MEMBERSHIP = 'membership',
}

export enum ActionsMenuType {
  EDIT_MEMBERSHIP = 'editMembership',
  DUPLICATE_MEMBERSHIP = 'duplicateMembership',
  DELETE_MEMBERSHIP = 'deleteMembership',
  TERMINATE_MEMBERSHIP = 'terminateMembership',
  EXPORT_REPORT = 'exportReport',
}

export enum BillingCycleTypeDisplay {
  day = 'dy',
  week = 'wk',
  month = 'mo',
  year = 'yr',
}
export enum BillingCycleTypeLongDisplay {
  day = 'Day',
  week = 'Week',
  month = 'Month',
  year = 'Year',
}

export enum CycleType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum MembershipPlanPublishStatus {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
}

export enum ContractType {
  AUTO_RENEW = 'auto-renew',
  ONE_TIME = 'one-time',
}

export type MenuItem = {
  id: ScreensViews;
  image: string;
  title: string;
  button: string;
  withDetail: boolean;
  showTimestamp: boolean;
};

export type MembershipProduct = {
  id: string;
  membershipPlanId: string;
  productId: string;
  product?: Experience;
  name: string;
  maxNumberOfUse: number;
  price?: Price;

  createdUserId: string;
  createdAt: string;
  updatedUserId?: string;
  updatedAt?: string;
  deletedUserId?: string;
  deletedAt?: string;
};

export class MembersCount {
  totalActiveMembers: number;
  // number of TOTAL active members a plan has (including those gracefully cancelled)
  activeExpiringPlans: number;
  // number of plans that are active with expiration dates
  totalActivePlans: number;
  // number of active plans that have been purchased
}

export type MembershipPlan = {
  id: string;
  subscriptionId: string;
  subscriptionName?: string;
  name: string;
  maxNbOfConsumers: number; // Max number of family members eligible for the plan
  feePercentage?: number;
  feeAmount?: number;
  totalAmount?: number;
  price: number;
  autoRenew: boolean;
  billingCycleType: CycleType;
  billingCycleLength: number;
  contractType: CycleType;
  contractLength: number;

  refundPolicy?: RefundPolicy;
  taxRateId?: number;
  hasMembers: boolean; // used to indicated true/false if there are any active members using this plan
  membersCount: MembersCount;
  hasPurchaseHistory?: boolean;
  publishStatus: MembershipPlanPublishStatus;
  publishedAt: string;
  membershipProducts: MembershipProduct[];
  merchantId?: number;
  createdUserId: string;
  createdAt: string;
  updatedUserId?: string;
  updatedAt?: string;
  deletedUserId?: string;
  deletedAt?: string;
};

export type MemberPlan = {
  id: string;
  name: string;
  memberSince: string;
};

export type MembershipPhotos = {
  photo1?: string;
  photo2?: string;
  photo3?: string;
  photo4?: string;
};

export type Membership = {
  id: string;
  merchantId: number;
  name: string;
  description: string;
  coverPhoto?: string;
  photos: MembershipPhotos;
  type: SubscriptionType.MEMBERSHIP;
  membershipPlans: MembershipPlan[];
  createdUserId: string;
  createdAt: string;
  updatedUserId?: string;
  updatedAt?: string;
  deletedUserId?: string;
  deletedAt?: string;
};

export type MembershipProductDto = {
  productId: string;
  maxNumberOfUse: number;
};

export type MembershipPlanDto = {
  membershipId?: string;
  membershipPlanId?: string | undefined;
  subscriptionId?: string;
  name: string;
  maxNbOfConsumers: number; // Max number of family members eligible for the plan
  totalAmount: number | string;
  taxRateId?: number;
  billingCycleType: number | string;
  billingCycleLength: number | string;
  contractType: number | string;
  contractLength: number | string;
  autoRenew: boolean;
  membershipProducts: MembershipProductDto[];
};

export type MembershipDto = {
  subscriptionId: string;
  name: string;
  description: string;
};
