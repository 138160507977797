import { Typography } from '@silverstein-properties/inspirelabs-ui';
import { AnimatedBox } from './Note.styles';

interface NoteProps {
  text: string;
  isExpanded: boolean;
  maxLines?: number;
}

export const Note = ({ text, isExpanded, maxLines = 3 }: NoteProps) => {
  return (
    <AnimatedBox isExpanded={isExpanded} maxLines={maxLines}>
      <Typography component="div">{text}</Typography>
    </AnimatedBox>
  );
};
