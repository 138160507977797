import {
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { useMemo, useState } from 'react';
import {
  ActionsMenuType,
  Membership,
  MembershipPlanPublishStatus,
} from '@/types/MerchantMembership';
import { formatPricing } from '@/utils';
import RoundButton from 'components/RoundButton/Component';
import { useFeatureFlags } from '@/hooks';

export type MembershipRowProps = {
  membership: Membership;
  onClickMenuItem?: (type: ActionsMenuType, membershipId: string) => void;
};

export const MembershipRow = ({
  membership: { id, name, membershipPlans },
  onClickMenuItem = () => {},
}: MembershipRowProps) => {
  const {
    isDuplicateMembershipEnabled,
    isTerminateMembershipEnabled,
    isDeleteMembershipEnabled,
  } = useFeatureFlags();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [reportGenerated, setReportGenerated] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const canBeTerminated = useMemo<boolean>(
    () =>
      membershipPlans.length > 0 &&
      membershipPlans.some(
        membershipPlan =>
          membershipPlan?.hasMembers &&
          membershipPlan?.membersCount?.totalActivePlans >
            membershipPlan?.membersCount?.activeExpiringPlans
      ),
    [membershipPlans]
  );

  const canBeDeleted = useMemo<boolean>(
    () =>
      membershipPlans.every(
        membershipPlan =>
          membershipPlan.publishStatus ===
            MembershipPlanPublishStatus.UNPUBLISHED &&
          membershipPlan?.membersCount?.totalActivePlans === 0 &&
          !membershipPlan?.hasPurchaseHistory
      ),
    [membershipPlans]
  );

  const totalActiveMembers = useMemo<number>(
    () =>
      membershipPlans.reduce(
        (acc, cur) => (cur?.membersCount?.totalActiveMembers || 0) + acc,
        0
      ),
    [membershipPlans]
  );

  const totalAverageIncome = useMemo<number>(
    () =>
      membershipPlans.reduce(
        (acc, cur) =>
          (cur?.membersCount?.totalActivePlans * cur.price || 0) + acc,
        0
      ),
    [membershipPlans]
  );

  return (
    <Grid container item alignItems="center" padding={2} xs={12}>
      <Grid item xs={2}>
        {/* Membership Name */}
        <Typography variant="subtitle1">{name}</Typography>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={2} textAlign="right">
        {/* Total Active Users */}
        <Typography variant="subtitle2">{totalActiveMembers}</Typography>
      </Grid>
      <Grid item xs={2} textAlign="right">
        {/* Total Monthly Income */}
        <Typography variant="subtitle2">
          {formatPricing(totalAverageIncome)}
        </Typography>
      </Grid>
      <Grid item xs textAlign="right">
        <>
          <RoundButton
            text="Actions"
            default="white-navy small"
            hover="blue small"
            handleClick={e => {
              handleClick(e);
            }}
          />
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{ mt: 1 }}
          >
            <MenuItem
              disableRipple
              data-testid="viewEditDetailsMembership"
              onClick={() =>
                onClickMenuItem(ActionsMenuType.EDIT_MEMBERSHIP, id)
              }
            >
              View/Edit membership details
            </MenuItem>
            {!reportGenerated && (
              <MenuItem
                disableRipple
                data-testid="exportReport"
                onClick={() => {
                  setReportGenerated(true);
                  onClickMenuItem(ActionsMenuType.EXPORT_REPORT, id);
                }}
              >
                Generate Membership Report
              </MenuItem>
            )}
            {isDuplicateMembershipEnabled && (
              <MenuItem
                data-testid="duplicateMembership"
                disableRipple
                onClick={() =>
                  onClickMenuItem(ActionsMenuType.DUPLICATE_MEMBERSHIP, id)
                }
              >
                Duplicate membership
                {/* TODO */}
              </MenuItem>
            )}
            {canBeTerminated && isTerminateMembershipEnabled && (
              <MenuItem
                data-testid="terminateMembership"
                disableRipple
                onClick={() =>
                  onClickMenuItem(ActionsMenuType.TERMINATE_MEMBERSHIP, id)
                }
              >
                Terminate membership
              </MenuItem>
            )}
            {canBeDeleted && isDeleteMembershipEnabled && (
              <MenuItem
                data-testid="deleteMembership"
                disableRipple
                onClick={() =>
                  onClickMenuItem(ActionsMenuType.DELETE_MEMBERSHIP, id)
                }
              >
                Delete membership
              </MenuItem>
            )}
          </Menu>
        </>
      </Grid>
    </Grid>
  );
};
