import { PriceType, SelectionType, SquareEnvironment } from '@/types';
import {
  TvIcon,
  CameraIcon,
  CateringIcon,
  CoffeeIcon,
  IntegrityIcon,
  PackageIcon,
  ConciergeIcon,
  MicrowaveIcon,
  RefrigeratorIcon,
  SinkIcon,
  UsbPluginIcon,
  WhiteboardIcon,
  WifiIcon,
  WirelessLaptopConnectionIcon,
  GrowthIcon,
} from '@silverstein-properties/inspirelabs-ui';
import { ForwardRefExoticComponent, SVGProps, RefAttributes } from 'react';
export * from './ExperienceDetailsModuleConstants';
export * from './MembershipDetailsModuleConstants';
export * from './MerchantTeamsModuleConstants';
export * from './featureFlagConstants';

export const ISO_DATE_FORMAT_REGEX =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

export const SERVICE_TYPE_CHOICES: SelectionType[] = [
  { id: 1, value: 1, label: "Indoor space (I'm hosting)" },
  {
    id: 2,
    value: 2,
    label: "Indoor space (Customer's location)",
  },
  { id: 3, value: 3, label: "Outdoor space (I'm hosting)" },
  {
    id: 4,
    value: 4,
    label: "Outdoor space (Customer's location)",
  },
  { id: 5, value: 5, label: 'Common area' },
  { id: 6, value: 6, label: 'Outdoor tour' },
];

export const EXPERIENCE_YEARS_CHOICES: SelectionType[] = [
  { id: 0, value: '0', label: '2024' },
  { id: 1, value: '1', label: '2023' },
  { id: 2, value: '2', label: '2022' },
  { id: 3, value: '3', label: '2021' },
  { id: 4, value: '4', label: '2020' },
  { id: 5, value: '5', label: '2019' },
  { id: 6, value: '6', label: '2018' },
  { id: 7, value: '7', label: 'Before 2018' },
];

//TODO -> add 'all day' option for experience length: https://spinspire.monday.com/boards/3397008425/pulses/4921420946
// ===> Note: Omit property 'id' from 'SelectionType' to match the required type of <InputField /> dropdownItems prop
// without breaking the logic of other code elsewhere.
export const HOURS_OPTIONS: Omit<SelectionType, 'id'>[] = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
];

// ===> Note: Omit property 'id' from 'SelectionType' to match the required type of <InputField /> dropdownItems prop
// without breaking the logic of other code elsewhere.
export const MINUTES_OPTIONS: Omit<SelectionType, 'id'>[] = [
  { label: '0', value: '0' },
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '15', value: '15' },
  { label: '20', value: '20' },
  { label: '25', value: '25' },
  { label: '30', value: '30' },
  { label: '35', value: '35' },
  { label: '40', value: '40' },
  { label: '45', value: '45' },
  { label: '50', value: '50' },
  { label: '55', value: '55' },
];

export const AUDIENCE_CHOICES: SelectionType[] = [
  { label: 'Families', id: 1, value: 'families' },
  { label: 'Adults(18+)', id: 2, value: '18plus' },
  { label: 'Adults(21+)', id: 3, value: '21plus' },
  { label: 'Teens', id: 4, value: 'teens' },
  { label: 'Kids', id: 5, value: 'kids' },
  { label: 'Professionals', id: 6, value: 'professionals' },
];

export const ACTIVITY_CHOICES: SelectionType[] = [
  { label: 'Light', id: 1, value: 'light' },
  { label: 'Moderate', id: 2, value: 'moderate' },
  { label: 'Extreme', id: 3, value: 'extreme' },
];

export const CANCELLATION_OPTIONS: SelectionType[] = [
  { id: 1, value: 'full-1w-prior', label: '1 week' },
  { id: 2, value: 'full-5d-prior', label: '5 days' },
  { id: 3, value: 'full-3d-prior', label: '3 days' },
  { id: 4, value: 'full-48h-prior', label: '48 hrs.' },
  { id: 5, value: 'full-24h-prior', label: '24 hrs.' },
  { id: 6, value: 'full-anytime', label: 'Anytime' },
];

export const REFUND_REASONS: SelectionType[] = [
  { id: 1, value: 'duplicate', label: 'Duplicate' },
  { id: 2, value: 'fraudulent', label: 'Fraudulent' },
  { id: 3, value: 'requested_by_customer', label: 'Requested by customer' },
];

export const PRICE_UNIT_CHOICES = [
  { id: 1, value: 'PRICE_PER_PERSON', label: 'Per guest' },
  { id: 2, value: 'PER_HOUR', label: 'Per hour' },
  { id: 3, value: 'FLAT_FEE', label: 'Per booking' },
  { id: 4, value: 'PER_DAY', label: 'Per day' },
];

export const PRICE_TYPE_MAP: Record<PriceType, string> = {
  PRICE_PER_PERSON: 'person',
  PER_HOUR: 'hour',
  FLAT_FEE: 'booking',
  PER_DAY: 'day',
  VIA_QUOTE: 'quote',
  MEMBERSHIP: 'membership',
};

export const AMENITIES_ICON_MAP: Record<
  string,
  ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, 'ref'> & RefAttributes<SVGSVGElement>
  >
> = {
  TvIcon,
  CameraIcon,
  CateringIcon,
  CoffeeIcon,
  ConciergeIcon,
  PackageIcon,
  IntegrityIcon,
  WhiteboardIcon,
  WifiIcon,
  UsbPluginIcon,
  WirelessLaptopConnectionIcon,
  RefrigeratorIcon,
  MicrowaveIcon,
  SinkIcon,
  GrowthIcon,
};

export const SQUARE_ENVIRONMENT = Object.values(SquareEnvironment).map(
  (squareEnvironment, index) => ({
    id: index,
    value: squareEnvironment,
    label: squareEnvironment,
  })
);

//setting the character limit of input fields(SHORT is for things like titles, LONG is for things like descriptions)
export const TEXT_AREA_LIMIT_SHORT = 30;
export const TEXT_AREA_LIMIT_MEDIUM = 200;
export const TEXT_AREA_LIMIT_MEDIUM_PLUS = 250;
export const TEXT_AREA_LIMIT_LONG = 900;
export const TEXT_AREA_LIMIT_EXTRA_LONG = 1500;
export const TEXT_AREA_LIMIT_LUDICROUSLY_LONG = 3000;

//setting the limit of categories and subcategories an experience can have
export const MAIN_CATEGORY_LIMIT = 2;
export const SUB_CATEGORY_LIMIT = 5;

//the recipient of the contact form(should be the email of someone on the product team)
export const CONTACT_FORM_RECIPIENT = process.env.REACT_APP_SUPPORT_EMAIL;

// default platform fee
export const DEFAULT_PLATFORM_FEE = 1500;

// default processing fee
export const DEFAULT_PROCESSING_FEE = 300;

export const WEEKDAY_BUTTON_SIZE = 44;

export const TAX_EXEMPT_PRICING = { id: 0, label: 'Exempt - 0%', value: 0 };

export const SEARCH_BOX = {
  WIDTH_LONG: 640,
  WIDTH_SHORT: 280,
  HEIGHT: 44,
};
// Default query params for user list
export const DEFAULT_MEMBER_SEARCH_PARAMS = {
  PAGE: 0,
  PAGE_SIZE: 20,
  SORT: 'asc',
};

export const DEFAULT_SEARCH_PARAMS = {
  PAGE: 0,
  PAGE_SIZE: 20,
  SORT: 'asc',
};

export const TAX_EXEMPT_PRICING_NO_LABEL = { id: 0, label: '0%', value: 0 };
