import { useState } from 'react';
import {
  useTheme,
  Typography,
  Container,
  Stack,
  InputField,
  Button,
} from '@silverstein-properties/inspirelabs-ui';
import { TEXT_AREA_LIMIT_LUDICROUSLY_LONG } from '@/constants';

export const NoteModal = ({
  close,
  submit,
  initialNote,
  noteId,
}: {
  close: () => void;
  submit: (note: string, isEdit: boolean, noteId?: number) => void;
  initialNote?: string;
  noteId?: number;
}) => {
  const theme = useTheme();
  const [note, setNote] = useState(initialNote || '');
  const isEditing = Boolean(initialNote);

  return (
    <Container sx={{ padding: theme.spacers.xl }}>
      <Stack spacing={theme.spacers.xl}>
        <Typography variant="subtitle1">
          {isEditing ? 'Edit note' : 'Add a note'}
        </Typography>
        <InputField
          label="Enter note..."
          multiline
          minRows={5}
          maxRows={12}
          placeholder="Type your note here..."
          helperText={`${note.length}/${TEXT_AREA_LIMIT_LUDICROUSLY_LONG}`}
          error={note.length > TEXT_AREA_LIMIT_LUDICROUSLY_LONG}
          value={note}
          onChange={e => setNote(e.target.value)}
        />
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button variant="text" size="small" onClick={close}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => submit(note, isEditing, noteId)}
            disabled={
              note.length > TEXT_AREA_LIMIT_LUDICROUSLY_LONG || !note.trim()
            }
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};
