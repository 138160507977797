import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';
import { useAuth } from './useAuth';
import { Note } from '../types/Note';

export const useNotes = () => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();
  const merchantId = user?.merchantUser?.merchantId;

  if (!merchantId || !user) {
    throw new Error('Merchant ID or user not found');
  }

  const createdUserId = user.id;

  const createNote = async ({
    userId,
    content,
  }: {
    userId: string;
    content: string;
  }): Promise<Note> => {
    return await apiClient.users.createNote({
      userId,
      merchantId: merchantId.toString(),
      content,
      createdUserId,
    });
  };

  const updateNote = async ({
    noteId,
    content,
  }: {
    noteId: number;
    content: string;
  }): Promise<Note> => {
    return await apiClient.users.updateNote({
      noteId,
      content,
      updatedUserId: createdUserId,
    });
  };

  return {
    createNote: useMutation({
      mutationFn: createNote,
    }),
    updateNote: useMutation({
      mutationFn: updateNote,
    }),
  };
};

export const useNote = (userId: string, merchantId: string) => {
  const apiClient = ApiClient();

  const {
    data: note,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ['note', userId, merchantId],
    queryFn: () => apiClient.users.getNote({ userId, merchantId }),
    enabled: !!userId && !!merchantId,
  });

  return {
    note,
    isLoading,
    error,
    refetch,
  };
};
